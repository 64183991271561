import React, { useLayoutEffect, useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Context } from '../';
import PropTypes from 'prop-types';

const RecordButton = ({ setText = () => {}, submitForm = () => {} }) => {
    const { recognizer, activeIndex } = useContext(Context);
    const [recording, updateRecording] = useState(false);

    useLayoutEffect(() => {        
        if (!recognizer) return;
        
        recognizer.recognized = async (thing, result) => {
            if (result.privResult.privText) {
                submitForm({
                    preventDefault: () => {},
                }, result.privResult.privText);
            }
        };

        return () => recognizer.recognized = () => {};
    }, [recognizer, activeIndex, setText, submitForm]);

    useEffect(() => {
        if (recording) {
            recognizer.startContinuousRecognitionAsync();
        } else {
            recognizer.stopContinuousRecognitionAsync();
        }
    }, [recording])

    const toggleRecord = (e) => {
        e.preventDefault();
        updateRecording(!recording);
    };

    return (
        <Button
            variant={recording ? 'danger' : ''}
            className="record-button"
            onTouchStart={e => toggleRecord(e)}
            onMouseDown={e => toggleRecord(e)}
            onTouchEnd={e => toggleRecord(e)}
            onMouseUp={e => toggleRecord(e)}
        >🎙️</Button>
    );

}

RecordButton.propTypes = {
    setText: PropTypes.func,
    submitForm: PropTypes.func,
}

export default RecordButton;
