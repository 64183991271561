

import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
const speechConfig = SpeechSDK.SpeechConfig.fromSubscription("a9ed366e4cf049f1b188dcb04bbbeac7", "uksouth");
speechConfig.speechRecognitionLanguage = "en-US";
speechConfig.speechSynthesisVoiceName = "en-US-DavisNeural";
speechConfig.setProfanity(2);

export const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig);

export const speak = (text) => {
	return synthesizer.speakSsmlAsync(`
	<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US">
		<voice name="en-US-DavisNeural"><s />
			<mstts:express-as style="friendly">
				<prosody rate="10%">
				${text}
				</prosody>
			</mstts:express-as>
		<s /></voice>
	</speak>
  `);
}