import games from './games';

const reducer = (state, action) => {
	if (action.type === 'update_last') {
		console.log({ action });
		return {
			...state,
			loading: false,
			announce: action.payload.text,
			messages: [...state.messages.slice(0, -1), {
				...action.payload,
			}]
		}
	}

	if (action.type === 'add_message') {
		console.log('add_message');
		if (action.payload.type === 'human') {
			return {
				...state,
				loading: true,
				messages: [...state.messages, { ...action.payload }]
			};
		}

		if (action.payload.type === 'ai') {
			return {
				...state,
				announce: action.payload.text,
				messages: [...state.messages, { ...action.payload }]
			};
		}
	}

	if (action.type === 'set_speak') {
		localStorage.setItem('speak', action.payload);

		return {
			...state,
			talkToMe: action.payload,
		};
	}

	if (action.type === 'set_dark') {
		localStorage.setItem('dark-mode', action.payload);

		return {
			...state,
			dark: action.payload,
		};
	}

	if (action.type === 'set_indexes') {

		return {
			...state,
			indexes: action.payload.games,
			version: action.payload.version
		};
	}

	if (action.type === 'set_active_game') {
		return {
			...state,
			activeGame: action.payload,
			messages: [{
				type: 'assistant',
				text: action.payload.summary || 'Sorry, I don\'t seem to have a summary of this game available..',
			}]
		}
	}

	if (action.type === 'set_active_index') {
		console.log({ state, action });

		if (!action.payload) {
			return state;
		}

		// console.log(state.indexes, action.payload);
		// return state;
		const { summary = false } = state.indexes.filter(item => item.slug === action.payload)[0];

		// console.log({ summary });
		return {
			...state,
			activeIndex: action.payload,
			messages: [{
				type: 'assistant',
				text: summary || 'Sorry, I don\'t seem to have a summary of this game available..',
			}]
		};
	}


	if (action.type === 'show_blocker') {
		return {
			...state,
			showBlocker: true,
		};
	}


	if (action.type === 'hide_blocker') {
		return {
			...state,
			showBlocker: false,
		};
	}

	if (action.type === 'set_recieving') {
		console.log('set_recieving');
		return {
			...state,
			recieving: true,
		};
	}

	if (action.type === 'update_recieved') {
		console.log('update_recieved');
		return {
			...state,
			messages: [...state.messages.slice(0, -1), {
				type: 'ai',
				text: action.payload,
			}]
		};
	}
	if (action.type === 'end_recieving') {
		console.log('end_recieving');
		return {
			...state,
			recieving: false,
		};
	}

	if (action.type === 'set_user') {
		localStorage.setItem('user', action.payload);
        console.log('ypu we here');
		return {
			...state,
			loggedIn: action.payload,
		};
	}

	if (action.type === 'logout_user') {
		localStorage.removeItem('user');
		return {
			...state,
			loggedIn: false,
		};
	}

	console.log('Unknown action. ' + action.type);

	return state;
};

export default reducer;