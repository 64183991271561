
import React, { useContext, useState, useRef, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Context } from '../';
import RecordButton from '../RecordButton/RecordButton';

const ChatInput = () => {
	const { 
		dispatch,
		activeGame,
		loading,
		apiReq,
	 } = useContext(Context);
	 
	const [text, setText] = useState('');
	const inputRef = useRef();

	const submitForm = useCallback(async (e, newText = false) => {
		e.preventDefault();

		if (loading) return;

		dispatch({
			type: 'add_message',
			payload: {
				type: 'human',
				text: newText || text,
			},
		});

		setTimeout(() => {
			dispatch({
				type: 'add_message',
				payload: {
					type: 'ai',
					text: '💬',
				},
			});
		}, 1000)

		setText('awaiting response...');

		const response = await apiReq(`/games?question=${newText || text}&game=${activeGame.slug}`, null, null, false);
		

		const reader = await response.body.getReader();
		const decoder = new TextDecoder();
		let buffer = "";

		dispatch({
			type: 'set_recieving',
		});

        while (true) {
			let { value, done } = await reader.read();
			try {
				if (done) {
					dispatch({
						type: 'end_recieving',
					});
	
					dispatch({
						type: 'update_last',
						payload: {
							type: 'ai',
							text: buffer,
						},
					});
	
					setText('');
	
					setTimeout(() => {
						inputRef.current.focus();
					}, 10);
	
					break;
				}
	
				const decoded = await decoder.decode(value, { stream: true });
				buffer += decoded;

				dispatch({
					type: 'update_recieved',
					payload: buffer,
				});
			} catch (err) {
				console.error(err);
				dispatch({
					type: 'end_recieving',
				});

				dispatch({
					type: 'add_message',
					payload: {
						type: 'ai',
						text: 'something appears to have gone awry, error logged',
					},
				});
			}
        }
	}, [text, activeGame]);

	if (!activeGame) {
		return null;
	}

	return (
		<div className="chat-message clearfix">
			<div className="input-group mb-0">
				<Form onSubmit={async (e) => await submitForm(e)}>
					<div className="input-group">
						<input ref={inputRef} disabled={loading} type="text" className="form-control" placeholder="Enter text here..." value={text} onChange={(e) => setText(e.target.value)} />
						<Button disabled={loading || text === ''} type="submit">{loading ? '⏳' : 'Send'}</Button>
						<RecordButton setText={setText} submitForm={submitForm} />
					</div>
				</Form>
			</div>
		</div>
	);
};

export default ChatInput;