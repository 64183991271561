import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Context } from '../';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router-dom';
import apiReq from '../../helpers/apiReq';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './drop-up.scss';

const DropUp = () => {
	const { 
		indexes = [],
		dispatch,
		showBlocker,
		setMenuOpen,
		showTypeahead,
		setShowTypehead,
	} = useContext(Context);

	const [singleSelections, setSingleSelections] = useState([]);
	const [loadingResults, setLoadingResults] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!singleSelections.length) return;
		navigate(`/${singleSelections[0].slug}`)
	}, [singleSelections, dispatch, navigate]);

	useEffect(() => {
		if (showTypeahead) {
			setTimeout(() => {
				// setSingleSelections([]);
				dispatch({
					type: 'show_blocker',
				});
			});
		}
	}, [showTypeahead])

	useEffect(() => {
		if (!showBlocker) {
			setShowTypehead(false);
		}
	}, [showBlocker, setShowTypehead]);

	const sortedGameNames = indexes.sort((a, b) => {
		if (a.gameName < b.gameName) {
			return -1;
		  }
		  if (a.gameName > b.gameName) {
			return 1;
		  }
		  return 0;
	});

	const handleSelection = selected => {
		setSingleSelections(selected);

		if (selected.length) {
			dispatch({
				type: 'hide_blocker',
			});
		}
	}

	const handleSearch = async (partial) => {

		setLoadingResults(true);
		const { result } = await apiReq(`/games/search?` + new URLSearchParams({
			search: partial,
		}).toString(), 'GET', null, true);

		dispatch({
			type: "set_indexes",
			payload: {
				games: result,
				version: 'hizzrag',
			}
		});

		setLoadingResults(false);
	}


	return (
		<div className="search-inner">
			<div className="input-group justify-content-end">
				{showTypeahead && <AsyncTypeahead
				isLoading={loadingResults}
				id="basic-typeahead-single"
				labelKey="gameName"
				onChange={handleSelection}
				options={sortedGameNames}
				placeholder="Choose/type a game..."
				selected={singleSelections}
				// filterBy={}
				// ref={inputRef}
				minLength={2}
				onSearch={handleSearch}
				autoFocus={true}
				renderMenuItemChildren={({ gameName, published }) => {
					return (
						<>
							{gameName}
							{published ? <small> - {published}</small> : ''}
						</>
					)
				}}
				/>}
				{!showTypeahead && (<Button variant="success" id="dropdown-basic" onClick={() => setMenuOpen(true)}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
						<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
					</svg>
				</Button>)}
			</div>
		</div>
	)
};

export default DropUp;