import React, { useState, useContext } from "react";
import { Button, Form, Modal} from 'react-bootstrap';
import { Context } from "../";
import apiReq from "../../helpers/apiReq";
import PropTypes from 'prop-types';

import './email-overlay.scss';

const EmailOverlay = ({
    visible = false
}) => {

    const { setEmailOverlay } = useContext(Context)
    const [name, setName] = useState();
    const [body, setBody] = useState();
    const [email, setEmail] = useState();
    const [sending, setSending] = useState(false);

    const send = async (e) => {
        e.preventDefault();
        setSending(true);

        const result = await apiReq('/send', 'POST', {
            name,
            from: email,
            body,
        }, true);

        if (result.status === 200) {
            setEmailOverlay(false);
            setSending(false);
        }
    } 

    return (
        <Modal show={visible} onHide={() => setEmailOverlay(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Get in touch</Modal.Title>
        </Modal.Header>
        <Form onSubmit={async (e) => await send(e)} style={{ width: '100%' }}>
        <Modal.Body>
                <div className="input-group mb-3">
                    <p>If you want me to add games, or you have ideas or bugs, or wanna collaborate..... let me know</p>
                </div>
                <div className="input-group mb-3">
                    <input disabled={sending} type="text" className="form-control" placeholder="Your name" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="input-group mb-3">
                    <input disabled={sending} type="email" className="form-control" placeholder="Your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="input-group mb-3">
                    <input disabled={sending} type="textarea" rows="10" className="form-control" placeholder="Your message" value={body} onChange={(e) => setBody(e.target.value)} required />
                </div>
                <div className="input-group">
                {/* <Button disabled={sending} type="submit">Send</Button>
                <Button variant="danger" disabled={sending} type="reset" onClick={() => setEmailOverlay(false)}>Close</Button> */}
                </div>                        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="submit">
            Send
          </Button>
          <Button variant="primary" onClick={() => setEmailOverlay(false)}>
            Close
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    )
}

EmailOverlay.propTypes = {
  visible: PropTypes.bool,
}

export default EmailOverlay;



