import config from '../config.js';

const getData = async (url, method, body, useJson = true) => {
	const options = {};

	if (method) {
		options.method = method;
	}

	if (body) {
		options.body = JSON.stringify(body);
		options.headers = {
			'Content-Type': 'application/json',
		};
	}

	const data = await fetch(`${config.API_URL}${url}`, { ...options });

	if (!useJson) {
		console.log(data);
		return data;
	}

	const json = await data.json();

	const obj = json.length ? { result: json } : { ...json };
	return {
		...obj,
		status: data.status
	};
};

export default getData;