const games = {
    "40k-core": 'Warhammer 40k Core',
    "7wonderduel": "7 Wonders Duel",
    "7wonders": "7 Wonders Base Game",
    "botc": "Blood on the ClockTower",
    "brass-birmingham": "Brass: Birmingham",
    "catan": "Base Catan (3-4 players)",
    "cockroach-poker": "Cockroach Poker/Kakerlaken Poker",
    // "chat": "Basic Chat",
    "deep-sea-adventure": "Deep Sea Adventure",
    "dnd-5e": "D&D 5th Edition",
    "dnd-basic": "D&D Basic Rules",
    "forsale": "For Sale",
    "heat": "Heat: Pedal to the Metal",
    "heckmeck": "Heckmeck",
    "imperium-horizons": "Imperium: Horizons",
    "jaipur": "Jaipur",
    "just-one": "Just One",
    "libertalia": "Libertalia: Winds of Galescrest",
    "lotrtcg": "Lord of the Rings TCG",
    "machi-kori": "Machi Koro 2",
    "resistance-v2": "Resistance",
    "TheResistanceHiddenAgendarules": "Resistance: Hidden Agenda",
    "shadowrun-5e": "Shadowrun 5th Edition",
    "splendor": "Splendor",
    "splendor-duel": "Splendor Duel",
    "trains": "Trains",
    "wingspan": "Wingspan",
    "wingspan-oceania": "Wingspan: Oceania",
    "wingspan-europe": "Wingspan: Europe",
    // "pirates"
    // "resistance": "Resistance",
};

export default games;