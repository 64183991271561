import React, { useContext, useRef, useEffect } from 'react';
import { AIMessage, HumanMessage, DropUp, Context, ChatInput } from '../'
import { useLoaderData } from "react-router-dom";
import apiReq from '../../helpers/apiReq';

const Chat = () => {
    const { messages, recieving, dispatch, showBlocker, activeGame } = useContext(Context);
    const chatRef = useRef();
    const heightRef = useRef();
    const { game } = useLoaderData();

    useEffect(() => {
        const getGame = async () => {
            console.log('game is here', game);

            if (game) {
                const activeGame = await apiReq(`/games/data/${game}`, 'GET', null, true);
                console.log({ activeGame });
    
                dispatch({
                    type: 'set_active_game',
                    payload: activeGame,
                })
            }
        }

        getGame();

    }, [game])

    useEffect(() => {
        if (chatRef.current && heightRef.current) {
            chatRef.current.scrollTo({
                top: heightRef.current.offsetHeight,
                behavior: 'smooth',
            });
        }
    }, [messages]);

    const interval = useRef();

    useEffect(() => {
        if (recieving) {
            interval.current = setInterval(() => {
                chatRef.current.scrollTo({
                    top: heightRef.current.offsetHeight,
                    behavior: 'smooth',
                });
            }, 500);
        } else {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = false;
            }
        }
    }, [recieving]);

    const blockerClassname = ['blocker', showBlocker && 'visible'].filter(Boolean).join(' ');

    const blockerClicked = () => {
        if (showBlocker) {
            dispatch({
                type: "hide_blocker",
            });
        }
    }

    return (
        <div className="row clearfix">
            <div className="col-lg-12">
                <div className="card chat-app">
                    <div className="chat">
                        <div className="chat-header clearfix">
                            <div className="row">
                                <div className="col avatar-container">
                                    <div className="chat-about">
                                        <h6 className="h4 mb-0 fw-bold"> {activeGame ? activeGame.name : 'Welcome to tinybrain.club'}</h6>
                                    </div>
                                </div>
                                <div className="col-auto text-right dropup-container">
                                    <DropUp />
                                </div>
                            </div>
                        </div>
                        <div className={blockerClassname} onClick={blockerClicked}></div>
                        <div className="scroll" style={{ flex: 1, overflowY: 'scroll' }} ref={chatRef}>
                            <div className="chat-history">
                                <ul className="m-b-0" ref={heightRef}>
                                    {messages.map(({ type, text }) => type === 'human' ? <HumanMessage key={text} message={text} /> : <AIMessage key={text} message={text} />)}
                                </ul>
                            </div>
                        </div>
                        <ChatInput />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;