import React, { useContext } from "react";
import { Context } from "..";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";

const OffcanvasContainer = () => {
    const { 
        setMenuOpen,
        showTypeahead,
        setShowTypehead,
        dispatch,
        dark,
        setDark,
        talkToMe,
        setEmailOverlay,
        menuOpen,
        version,
    } = useContext(Context);

	const handleButton = e => {
		e.preventDefault();
		setShowTypehead(!showTypeahead);
		setMenuOpen(false);
	}

    const toggleDark = () => {
		localStorage.setItem('dark-mode', !dark);
		dark ? document.querySelector('#root').classList.remove('dark') : document.querySelector('#root').classList.add('dark');
		setDark(!dark);
	}

	const handleDark = e => {
		e.preventDefault();
		toggleDark();
		setMenuOpen(false);
	}

	const toggleSpeak = e => {
		e.preventDefault();
		setMenuOpen(false);
        dispatch({
            type: 'set_speak',
            payload: !talkToMe,
        });
	}

	const handleContact = (e) => {
		e.preventDefault();
		setMenuOpen(false);
		setEmailOverlay(true);
	}

    return (
            <Offcanvas show={menuOpen} onHide={() => setMenuOpen(false)} placement='end' backdropClassName="urmom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>What do you want to do?</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/home" onClick={handleButton}>
                    <div className="dropdown-item-container" style={{ display: 'flex' }}>
                        <div className="icon" style={{ marginRight: 8, marginTop: -2 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                            </svg>	
                        </div>
                        <div>Search</div>
                    </div>
                </Nav.Link>
                <Nav.Link eventKey="link-1" onClick={handleDark}>
                    <div className="dropdown-item-container" style={{ display: 'flex' }}>
                        <div className="icon" style={{ marginRight: 8, marginTop: -2 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lamp" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M5.04.303A.5.5 0 0 1 5.5 0h5c.2 0 .38.12.46.303l3 7a.5.5 0 0 1-.363.687h-.002q-.225.044-.45.081a33 33 0 0 1-4.645.425V13.5a.5.5 0 1 1-1 0V8.495a33 33 0 0 1-4.645-.425q-.225-.036-.45-.08h-.003a.5.5 0 0 1-.362-.688l3-7ZM3.21 7.116A31 31 0 0 0 8 7.5a31 31 0 0 0 4.791-.384L10.171 1H5.83z"/>
                                <path d="M6.493 12.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.052.075l-.001.004-.004.01V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"/>
                            </svg>
                        </div>
                        <div>{dark ? 'Light' : 'Dark'} mode</div>
                    </div>
                </Nav.Link>
                <Nav.Link eventKey="link-2" onClick={toggleSpeak}>
                    <div className="dropdown-item-container" style={{ display: 'flex' }}>
                        <div className="icon" style={{ marginRight: 8, marginTop: -2 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat" viewBox="0 0 16 16">
                                <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105"/>
                            </svg>
                        </div>
                        <div>{talkToMe ? 'Disable voice' : 'Enable Voice'}</div>
                    </div>
                </Nav.Link>
                <Nav.Link eventKey="disabled" onClick={handleContact}>
                    <div className="dropdown-item-container" style={{ display: 'flex' }}>
                        <div className="icon" style={{ marginRight: 8, marginTop: -2 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                            </svg>
                        </div>
                        <div>Get in touch</div>
                    </div>
                </Nav.Link>
                
                </Nav>
                <div className="offcanvas-footer">
                    <h6>Made in the UK with ☕.</h6>
                    <h6 className="smaller">Build {version.slice(0,8)}</h6>
                </div>
                </Offcanvas.Body>
            </Offcanvas>
    )
}

export default OffcanvasContainer;