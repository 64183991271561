import Markdown from 'react-markdown';
import img from '../../img/brain.png'

const AIMessage = ({ message }) => (
	<li className="clearfix">
		<div className="message-data">
			<img src={img} alt="avatar" />
			<span className="message-data-time"><strong>Tinybrain</strong></span>
		</div>
		<div className="message my-message markdown-body"><Markdown>{message}</Markdown></div>
	</li>
);

export default AIMessage;



