import React from 'react';
import Markdown from 'react-markdown';
import img from '../../img/you.png'
import PropTypes from 'prop-types';

const HumanMessage = ({ message }) => (
	<li className="clearfix">
		<div className="message-data text-right">
			<span className="message-data-time"><strong style={{ paddingRight: 10 }}>You</strong></span>
			<img src={img} alt="avatar" />
		</div>
		<div className="message other-message float-right markdown-body"><Markdown>{message}</Markdown></div>
	</li>
);

HumanMessage.propTypes = {
	message: PropTypes.string,
};

export default HumanMessage;