import React, { useEffect, useReducer, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
	useRouteError,
	createBrowserRouter,
	RouterProvider,
	useLoaderData
} from "react-router-dom";

import reducer from './reducer';
import { Chat, Context, EmailOverlay, OffcanvasContainer } from './components';
import apiReq from './helpers/apiReq';
import { speak, recognizer } from './helpers/speechapi';

import './App.css';
import './styles/markdown.scss';
import 'react-toastify/dist/ReactToastify.css';
import './styles/dark-mode.scss';

export async function loader({ params }) {
    return { game: params.game };
}

const ErrorBoundary = () => {
    const { game } = useLoaderData();
	let error = useRouteError();
	console.error(error);
	return <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}><h1>Dang, {game} doesn&apos;t seem to exist. Sadface.</h1></div>;
}

  const router = createBrowserRouter([
	{
		path: "/",
		element: <Chat />,
		loader: loader,
	},
	{
	  path: "/:game",
	  element: <Chat />,
	  loader: loader,
	  errorElement: <ErrorBoundary />
	},
  ]);

const App = () => {
	const [state, dispatch] = useReducer(reducer, { messages: [
		{
			type: 'human',
			text: 'What is this? What can i do here? what?!',
		},
		{
			type: 'assistant',
			text: 'I\'m Tinybrain.. I\'m an AI that specialises in game rules.. pick a game from the top right icon, then ask me questions about the games rules!'
		}
	], activeIndex: false, indexes: [], talkToMe: localStorage.getItem('speak') === "true" });

	const [ready, setReady] = useState(false);
	const [dark, setDark] = useState(localStorage.getItem('dark-mode') === 'true');
	const [showEmailOverlay, setEmailOverlay] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [showTypeahead, setShowTypehead] = useState(false);
	const [version, setVersion] = useState('unset');
	const { loggedIn, announce, talkToMe } = state;

	useEffect(() => {
		if (localStorage.getItem('user')) {
			dispatch({
				type: 'set_user',
				payload: localStorage.getItem('user')
			});
		}

		if (localStorage.getItem('dark-mode')) {
			localStorage.getItem('dark-mode') === 'true' ? document.querySelector('#root').classList.add('dark') : document.querySelector('#root').classList.remove('dark');
		}
	}, []);

	useEffect(() => {
		if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
			if (!matchMedia('(display-mode: standalone)').matches) {
				toast("Remember, you can \"Add to Homescreen\"", {
					position: "bottom-right",
					theme: "light",
				});
			}
		}
	}, [])

	useEffect(() => {
		document.querySelector('#root').style.height = `${document.body.offsetHeight}px`;

		window.addEventListener("resize", () => {
			document.querySelector('#root').style.height = `${document.body.offsetHeight}px`;
		});
	}, []);

	useEffect(() => {
		if (announce && talkToMe === true) {
			speak(announce);
		}
	}, [announce, talkToMe]);

	useEffect(() => {
		console.log('activeIndex changed', state.activeIndex);
	}, [state.activeIndex])

	useEffect(() => {
	// 		const getGameIndexes = async () => {
	// 			let { games, version, status } = await apiReq('/indexes');

	// 			if (status === 401 && localStorage.getItem('user')) {
	// 				dispatch({
	// 					type: 'logout_user',
	// 				});
	// 			} else if (status === 200) {
	// 				dispatch({
	// 					type: 'set_indexes',
	// 					payload: {
	// 						games,
	// 						version
	// 					},
	// 				});

	// 			}
	// 		}

		const sayHi = async () => {
		let { version } = await apiReq('/hi');
			setVersion(version);
			setTimeout(() => setReady(true), 500);
		} 

		sayHi();
	}, []);

	const classNames = ['wrapper', ready ? 'wrapper--ready' : '', dark ? 'dark' : ''].join(' ');

	return (
		<div className={classNames}>
			<Context.Provider value={{ 
				...state,
				dispatch,
				apiReq,
				recognizer,
				ready,
				speak,
				setEmailOverlay,
				showEmailOverlay,
				dark,
				setMenuOpen,
				menuOpen,
				showTypeahead,
				setShowTypehead,
				setDark,
				version,
			}}>
				{/* {loggedIn ? <Chat /> : <LoginForm />} */}
				<RouterProvider router={router} />
				<div className="loader text-center">Chill ya beans..</div>
				<EmailOverlay visible={showEmailOverlay}/>
				<OffcanvasContainer />
				<ToastContainer />
			</Context.Provider>
		</div>
	);
};

export default App;
